export type GetMediaUrl = (url: string) => string

export function useGetMediaUrl() {
  const {
    public: { MEDIA_BASE_URL },
  } = useRuntimeConfig()
  /**
   *
   * @param url - The target url of the developer uploaded media file.
   * @returns
   */
  const getMediaUrl: GetMediaUrl = (url) =>
    `${MEDIA_BASE_URL}${`/media/${url}`.replaceAll('//', '/')}`

  return { getMediaUrl } as const
}
